import React from "react";
import GlobalNavigation, { Logo, CurrentApp, Helper, User } from "sriracha/dist/GlobalNavigation";
import Baton from "./components/Baton";
import "sriracha/dist/GlobalNavigation.scss";
import "./App.scss";

import BatonApiProvider from "providers/BatonApiProvider";

export default function App() {
  return (
    <div className="layout">
      <BatonApiProvider>
        <div className="sriracha">
          <GlobalNavigation>
            <Logo launchpadUrl={"https://www.wegalvanize.com"} />
            <CurrentApp appModuleName="Maestra's Baton" appUrl="/" />
            <Helper helpDocUrl={"https://www.wegalvanize.com"} supportUrl={"https://www.wegalvanize.com"} />
            <User
              username={"Username"}
              userProfileUrl={"https://www.wegalvanize.com"}
              logoutUrl={"https://www.wegalvanize.com"}
            />
          </GlobalNavigation>
        </div>
        <Baton />
      </BatonApiProvider>
    </div>
  );
}
